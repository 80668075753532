import { Panel } from '@Cortex';

import { forwardRef, useState } from 'react';
import * as S from './ProfileSubscription.styles';
import { isLifetimeUser, MembershipPanel, useIsLifetimeUser, useIsTrialUser } from '@Memberships';
import { TeamInfo } from '../../../../domains/User/Profile/TeamInfo';
import { useTeamSubscriptionInfo } from '../../../../hooks/useHasTeamSubscription';
import { useIsExpired } from '../../../../domains/Memberships/lenses/isExpired';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../reducers';
import { useTeams } from '../../../../hooks/useTeams';
import { Transition } from 'react-transition-group';
import { Elements } from '@stripe/react-stripe-js';
import { UpdateStripePaymentMethodPanel } from '@Payment';
import stripe, { ELEMENT_OPTIONS } from '../../../../utils/stripe';
import { ProfilePayment } from '../ProfilePayment/ProfilePayment';

export const ProfileSubscription = forwardRef<HTMLDivElement>((_, ref) => {
  const isTrialUser = useIsTrialUser();
  const isLifeTimeUser = useIsLifetimeUser();
  const { hasTeamSubscription } = useTeamSubscriptionInfo();
  const isExpired = useIsExpired();
  const legacyMembership = useSelector((state: RootReducerType) => state.user.membership);
  const { list: teamsList } = useTeams();
  const [openUpdatePaymentMethod, setOpenUpdatePaymentMethod] = useState(false);

  const hasIndividualSubscriptionSection =
    !hasTeamSubscription ||
    (hasTeamSubscription && !isTrialUser && (!isExpired || !legacyMembership?.isCancelled));

  return (
    <S.Container ref={ref}>
      {hasIndividualSubscriptionSection && (
        <MembershipPanel onClickUpdatePaymentDetails={() => setOpenUpdatePaymentMethod(true)} />
      )}

      {teamsList.length ? (
        <>
          <Panel padding="0">
            <TeamInfo />
          </Panel>
        </>
      ) : null}
      <Transition in={openUpdatePaymentMethod} mountOnEnter timeout={200} unmountOnExit>
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
                <UpdateStripePaymentMethodPanel onClose={() => setOpenUpdatePaymentMethod(false)} />
              </Elements>
            </S.FloatingWindow>
          );
        }}
      </Transition>

      {!isTrialUser && !isLifeTimeUser ? <ProfilePayment /> : null}
      <S.CancelText>
        All prices in USD. Applicable VAT, sales or other taxes may apply. Cancel your subscription
        at anytime.
      </S.CancelText>
    </S.Container>
  );
});

import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { Serving, Track } from '@Model';
import {
  getTrackName,
  getTrackId,
  TrackInformationCard,
  getTrackHasMultipleNELs,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackNeuralEffectLevel,
  getTrackGenreName,
} from '@Music';
import { getTrackVariationId } from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { removeFromFavorites, addToFavorites } from '../../../../actions';
import * as S from './NewTracks.styles';
import { getTrackRelatedActivities } from '../../../../../Music/lenses/getTrackRelatedActivities';
import { useState, useEffect } from 'react';

type TrackDictionary = {
  [key: string]: Serving;
};

type Props = {
  tracks: Serving[];
  error: Error | null;
  onTrackClickMore: (track: Track | Serving) => void;
};

export function NewTracks({ tracks, error, onTrackClickMore }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const [showAllTracks, setShowAllTracks] = useState(true);

  useEffect(() => {
    setShowAllTracks(tracks.length > 5 ? false : true);
  }, [tracks.length]);

  if (error) {
    return <TextBold>{error.message}</TextBold>;
  }

  const newTracks: Serving[] = [...(tracks || [])];

  const newlyReleasedTrackVariationsDictionary = newTracks.reduce((acc, track) => {
    acc[getTrackVariationId(track)] = track;
    return acc;
  }, {} as TrackDictionary);

  const tracksToRender = showAllTracks
    ? Object.values(newlyReleasedTrackVariationsDictionary)
    : Object.values(newlyReleasedTrackVariationsDictionary).slice(0, 5);

  function handleClickPlay(track: Serving) {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromNewlyReleasedTracks, {
      trackId: getTrackId(track),
      trackVariationId: getTrackVariationId(track),
      trackName: getTrackName(track),
    } as any);

    navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.NewTracks,
      trackName: getTrackName(track),
    });
  }

  if (tracks.length === 0) {
    return null;
  }

  return (
    <S.CardsContainer>
      <S.Title>Just added</S.Title>

      {tracksToRender.map(track => {
        const isFavorited = favorites.some(favorite => {
          return getTrackId(favorite) === getTrackId(track);
        });

        return (
          <S.CardContainer
            key={getTrackVariationId(track) || getTrackId(track)}
            data-testid="newReleaseTrackItem"
          >
            <TrackInformationCard
              hasMultipleNELs={getTrackHasMultipleNELs(track)}
              imageUrl={getTrackImageUrl(track)}
              isFavorited={isFavorited}
              isNewlyCreated={getTrackIsNewlyCreated(track)}
              moreIconTestId={`trackCardMoreInfo__${getTrackId(track)}`}
              neuralEffectLevel={getTrackNeuralEffectLevel(track)}
              relatedActivities={getTrackRelatedActivities(track)}
              subtitle={getTrackGenreName(track)}
              title={getTrackName(track)}
              onAddToFavorites={() => {
                dispatch(addToFavorites({ origin: TrackActionOrigins.Recents, track }));
              }}
              onClick={() => handleClickPlay(track)}
              onClickMore={() => onTrackClickMore(track)}
              onRemoveFromFavorites={() =>
                dispatch(
                  removeFromFavorites({
                    track,
                  }),
                )
              }
            />
          </S.CardContainer>
        );
      })}

      {!showAllTracks && (
        <S.ShowAllTracksButton
          aria-label="View More"
          data-testid="showAllTracksButton"
          role="button"
          onClick={() => setShowAllTracks(true)}
        >
          View More
        </S.ShowAllTracksButton>
      )}
    </S.CardsContainer>
  );
}

import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../actions/ui';
import { RootReducerType } from '../../../reducers';
import { getDeviceType } from '../../../utils/getDeviceType';
import { useAppDownloadModalExperiment } from '../../Utils/useAmplitudeExperiments';
import { FirstActivityModalDisplay } from './FirstActivityModal.display';
import { useCallback } from 'react';
import { MembershipStates, useIsTrialUser } from '@Memberships';

export function FirstActivityModal() {
  const dispatch = useDispatch();
  const { isMobile } = getDeviceType();
  const { isEnabled: isEnabledAppDownloadModal } = useAppDownloadModalExperiment();
  const { modalType } = useSelector((state: RootReducerType) => state.ui);
  const isActiveMembership = useSelector(
    (state: RootReducerType) => state.membership.state === MembershipStates.Active,
  );
  const isTrialUser = useIsTrialUser();
  const isNonTrialActiveUser = !isTrialUser && isActiveMembership;

  const emitEvent = useCallback((name: 'resume') => {
    window.dispatchEvent(new Event(name));
  }, []);

  const onClose = useCallback(() => {
    if (isEnabledAppDownloadModal && isMobile) {
      dispatch(setModal('appDownload'));
    } else {
      dispatch(setModal(null));
    }
    emitEvent('resume');
  }, [dispatch, emitEvent, isEnabledAppDownloadModal, isMobile]);

  return (
    <FirstActivityModalDisplay
      isOpen={modalType === 'firstActivity'}
      isPaymentSaved={isNonTrialActiveUser}
      onClose={onClose}
    />
  );
}

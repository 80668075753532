import { Action } from '@reduxjs/toolkit';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as analyticsActions from '../actions/analytics';
import * as uiActions from '../actions/ui';
import { uiSliceActions } from '../reducers/uiReducer';

function* sessionFinishedSaga(action: Action) {
  if (uiActions.setSessionFinished.match(action)) {
    yield put(uiSliceActions.setSessionFinished(action.payload));

    // only send analytics of the session finished is true
    if (action.payload) {
      yield put(analyticsActions.playerLogEvent('player_imfinished'));
    }
  }
}

function* setModalSaga(action: Action) {
  if (uiActions.setModal.match(action)) {
    yield put(uiSliceActions.setModalType(action.payload));
  }
}

function* setSuccessModalOpenSaga(action: Action) {
  if (uiActions.successModalOpen.match(action)) {
    yield put(uiSliceActions.setSuccessModal({ isOpen: true, ...action.payload }));
  }
}

function* setSuccessModalCloseSaga(action: Action) {
  if (uiActions.successModalClose.match(action)) {
    yield put(uiSliceActions.setSuccessModal({ isOpen: false }));
  }
}

function* clearSuccessModalSaga(action: Action) {
  if (uiActions.successModalClear.match(action)) {
    yield put(
      uiSliceActions.setSuccessModal({
        isDismissable: true,
        isOpen: false,
        title: null,
        description: null,
        actions: [],
      }),
    );
  }
}

function* setVolumeSaga(action: Action) {
  if (uiActions.setPlayerVolume.match(action)) {
    yield put(uiSliceActions.setVolume(action.payload));
  }
}

function* setShouldShowProTipsModal(action: Action) {
  if (uiActions.setShouldShowProTipsModal.match(action)) {
    yield put(uiSliceActions.setShouldShowProTipsModal(action.payload));
  }
}

function* setShouldShowNELTipsModal(action: Action) {
  if (uiActions.setShouldShowNELTipsModal.match(action)) {
    yield put(uiSliceActions.setShouldShowNELTipsModal(action.payload));
  }
}

function* setSessionModalContentSaga(action: Action) {
  if (uiActions.setSessionModalContent.match(action)) {
    yield put(uiSliceActions.setSessionModalContent(action.payload));
  }
}

function* setPlayerAnimationStateSaga(action: Action) {
  if (uiActions.setPlayerAnimationState.match(action)) {
    yield put(uiSliceActions.setPlayerAnimationState(action.payload));
  }
}

export default function* watchUISaga() {
  yield takeEvery(uiActions.setSessionFinished.type, sessionFinishedSaga);
  yield takeEvery(uiActions.setModal.type, setModalSaga);
  yield takeEvery(uiActions.setSessionModalContent.type, setSessionModalContentSaga);

  yield takeEvery(uiActions.successModalOpen.type, setSuccessModalOpenSaga);
  yield takeEvery(uiActions.successModalClose.type, setSuccessModalCloseSaga);
  yield takeEvery(uiActions.successModalClear.type, clearSuccessModalSaga);
  yield takeEvery(uiActions.setShouldShowProTipsModal.type, setShouldShowProTipsModal);
  yield takeEvery(uiActions.setShouldShowNELTipsModal.type, setShouldShowNELTipsModal);

  yield takeLatest(uiActions.setPlayerVolume.type, setVolumeSaga);
  yield takeLatest(uiActions.setPlayerAnimationState.type, setPlayerAnimationStateSaga);
}

import { trackListeningMilestone } from '@Analytics';
import { audioPlayerActions, isAudioPlayerRepeatEnabled } from '@AudioPlayer';
import { LISTENING_MILESTONES_TO_TRACK } from '@Globals';
import { Serving, TagTypes, DynamicActivity } from '@Model';
import { LegacySession as V3Session, DynamicSession } from '@Model';
import { getTrackId, getTrackName, musicActions } from '@Music';
import { getUser, userActions, UserPreferenceDisplayTypes, UserState } from '@User';
import { isAllowedToPlaySession, hasTeamAccess, isEmailVerified } from '@Utils';
import { ActionCreatorWithPayload, Action } from '@reduxjs/toolkit';
import { put, select, takeEvery, takeLatest, call, delay } from 'redux-saga/effects';

import * as analyticsActions from '../actions/analytics';
import * as sessionManagerActions from '../actions/sessionManager';
import * as uiActions from '../actions/ui';
import { RequestMethods } from '../api/client/types';
import { DA_PREFIXES } from '../constants';
import { getDynamicSession, Session } from '../domains/Session/lenses/useDynamicSession';
import { toggleSessionPreference } from '../domains/User/actions';
// reducers
import { RootReducerType } from '../reducers';
import { currentSessionSliceActions } from '../reducers/currentSession';
import { sessionManagerSliceActions } from '../reducers/sessionManager';
import { uiSliceActions } from '../reducers/uiReducer';
import { userSliceActions } from '../reducers/user';
import {
  PlayedActivity,
  SessionManagerStateType,
  UserInfoType,
  CreateSession,
  ChangeSessionDynamicActivity,
  CreateDynamicSession,
  MentalStates,
  CreateDynamicSessionFromJumpBackIn,
} from '../types';
import { activities } from '../utils/activities';
import { Analytics } from '../utils/analytics';
import { getDynamicActivityPlayerPath } from '../utils/getDynamicActivityPlayerPath';
// utils
import { Logger } from '../utils/logger';
import { requestSaga } from './httpRequest';

import { timerActions } from '@Timer';

import { saveRecentSession } from '../actions/recentSessions';
import { TimerSpecification } from '../domains/Session/components/JumpBackInModal/types';
import { selectRecentlyUsedTimers } from '../selectors/timer';
import { selectCurrentTrack } from '../selectors/music';

/** Session Management */

/**
 * Create a new dynamic session (90 min Deep Work, etc)
 * @param action sessionManagerActions.CreateSession
 */
function* createDynamicSessionSaga(action: ActionCreatorWithPayload<CreateDynamicSession>) {
  /**
   * This function does a few main things.
   * 1. Prepares new session to be played
   * 2. Checks the user's info and see if any messages need to be displayed to user.
   * - Gathers info it needs
   * - Requests new tracks from server for session
   * - Prepares those tracks
   * - Execute (ordered of most important for speed)
   *  -- Creates new session
   *  -- Update current playing track
   *  -- Fire analytics to record new session
   */

  if (sessionManagerActions.createDynamicSession.match(action)) {
    try {
      const dynamicActivityId = action.payload.sessionDynamicActivityId;
      const useSavedTimer = Boolean(action.payload.state?.useSavedTimer);

      yield put(sessionManagerSliceActions.update({ sessionStatus: 'loading' }));
      // Gather data
      const userInfo: RootReducerType['user']['info'] = yield select(
        (state: RootReducerType) => state.user.info,
      );
      const membership: RootReducerType['user']['membership'] = yield select(
        (state: RootReducerType) => state.user.membership,
      );
      const verification: RootReducerType['user']['verification'] = yield select(
        (state: RootReducerType) => state.user.verification,
      );
      const teams: RootReducerType['teams'] = yield select((state: RootReducerType) => state.teams);
      const user: UserState = yield select(getUser);
      const currentDynamicActivity: RootReducerType['sessionManager']['sessionDynamicActivity'] =
        yield select((state: RootReducerType) => state.sessionManager.sessionDynamicActivity);

      const { result: dynamicActivity } = (yield call(
        requestSaga,
        RequestMethods.GET,
        `/activities/dynamic/${dynamicActivityId}`,
        undefined,
        3,
      )) as {
        result: DynamicActivity | null;
      };

      const activeGenres = dynamicActivity
        ? user.mentalStatePreferences[dynamicActivity.mentalState.id as MentalStates].genreNames
        : null;
      const activeNeuralEffectLevels = dynamicActivity
        ? user.mentalStatePreferences[dynamicActivity.mentalState.id as MentalStates]
            .neuralEffectLevels
        : null;
      const defaultDisplayType = dynamicActivity
        ? user.mentalStatePreferences[dynamicActivity.mentalState.id as MentalStates].defaultDisplay
            .type
        : null;

      yield handleCheckVerificationAccess(membership, teams, verification);

      // Set the repeat track to off
      yield put(audioPlayerActions.setPlayStyleToDefault());

      // don't move this anywhere else. it will mess up session history data
      yield savePastSessionToHistory();

      // Request
      const body: any = {
        dynamicActivityId,
        version: 3,
      };

      if ((activeGenres || []).length) {
        body.genreNames = activeGenres;
      }
      if ((activeNeuralEffectLevels || []).length) {
        body.neuralEffectLevels = activeNeuralEffectLevels;
      }
      const { result } = (yield call(
        requestSaga,
        RequestMethods.POST,
        `/users/${userInfo?.id}/sessions?platform=web`,
        body,
        3,
      )) as {
        result: { servings: Serving[]; dynamicActivity: DynamicActivity };
      };

      // Execute

      const isMentalStateSwitching =
        currentDynamicActivity?.mentalState.id !== dynamicActivity?.mentalState.id;

      if (isMentalStateSwitching) {
        const currentTrack: ReturnType<typeof selectCurrentTrack> =
          yield select(selectCurrentTrack);

        yield put(timerActions.reset());
        yield put(
          sessionManagerSliceActions.initDynamicSession({
            sessionPlayType:
              defaultDisplayType === UserPreferenceDisplayTypes.Quotes ? 'QUOTES' : 'NORMAL',
            sessionActivity: result.dynamicActivity,
          }),
        );

        // if there are no sessions in progress and session is being started from a specific place,
        // (e.g. home mental state, time of day recommendation, jump back in recent tracks)
        // then we need to use the timer from the recently used timers for that mental state
        if (useSavedTimer && !currentTrack) {
          const timerSpecifications: ReturnType<typeof selectRecentlyUsedTimers> =
            yield select(selectRecentlyUsedTimers);

          const timerSpecificationForMentalState =
            timerSpecifications[dynamicActivity?.mentalState.id || ''];

          if (timerSpecificationForMentalState) {
            yield handleTimerSpecificationParsing(timerSpecificationForMentalState);
          }
        }
      } else {
        yield put(
          sessionManagerSliceActions.changeSessionDynamicActivity({
            sessionDynamicActivity: result.dynamicActivity,
          }),
        );
      }
      yield put(musicActions.receiveQueue(result.servings));

      const currentActivity = result.dynamicActivity;

      // TODO: this function should be examined
      yield put(
        currentSessionSliceActions.updateSession({
          mentalStateId: result.dynamicActivity.mentalState.id,
          activityId: currentActivity.id,
          startedFrom: action.payload.startedFrom || 'home',
          playedActivities: [
            {
              id: currentActivity.id,
              name: currentActivity.displayValue,
              type: currentActivity.type,
              duration: 0,
            },
          ],
        }),
      );

      yield put(saveRecentSession());
      yield put(analyticsActions.sessionLogEvent('session_start'));
    } catch (error) {
      action.payload.navigate('/');
      Logger.error(error);
    } finally {
      yield put(sessionManagerSliceActions.update({ sessionStatus: 'idle' }));
    }
  }
}

function* createDynamicSessionFromJumpBackInSaga(
  action: ActionCreatorWithPayload<CreateDynamicSessionFromJumpBackIn>,
) {
  if (sessionManagerActions.createDynamicSessionFromJumpBackIn.match(action)) {
    try {
      const navigate = action.payload.navigate;
      const { dynamicActivity, timerSpecification } = action.payload.recentSession;

      yield put(sessionManagerSliceActions.update({ sessionStatus: 'loading' }));
      // Gather data
      const userInfo: RootReducerType['user']['info'] = yield select(
        (state: RootReducerType) => state.user.info,
      );
      const membership: RootReducerType['user']['membership'] = yield select(
        (state: RootReducerType) => state.user.membership,
      );
      const verification: RootReducerType['user']['verification'] = yield select(
        (state: RootReducerType) => state.user.verification,
      );
      const teams: RootReducerType['teams'] = yield select((state: RootReducerType) => state.teams);
      const user: ReturnType<typeof getUser> = yield select(getUser);

      const activeGenres = dynamicActivity
        ? user.mentalStatePreferences[dynamicActivity.mentalState.id as MentalStates].genreNames
        : null;
      const activeNeuralEffectLevels = dynamicActivity
        ? user.mentalStatePreferences[dynamicActivity.mentalState.id as MentalStates]
            .neuralEffectLevels
        : null;
      const defaultDisplayType = dynamicActivity
        ? user.mentalStatePreferences[dynamicActivity.mentalState.id as MentalStates].defaultDisplay
            .type
        : null;

      yield handleCheckVerificationAccess(membership, teams, verification);

      // Set the repeat track to off
      yield put(audioPlayerActions.setPlayStyleToDefault());

      // don't move this anywhere else. it will mess up session history data
      yield savePastSessionToHistory();

      // Request
      const body: any = {
        dynamicActivityId: dynamicActivity.id,
        version: 3,
      };

      if ((activeGenres || []).length) {
        body.genreNames = activeGenres;
      }
      if ((activeNeuralEffectLevels || []).length) {
        body.neuralEffectLevels = activeNeuralEffectLevels;
      }

      navigate(`/player/${dynamicActivity.id}`, { state: { preventAutoSessionCreation: true } });

      const { result } = (yield call(
        requestSaga,
        RequestMethods.POST,
        `/users/${userInfo?.id}/sessions?platform=web`,
        body,
        3,
      )) as {
        result: { servings: Serving[]; dynamicActivity: DynamicActivity };
      };

      const sessionPlayType =
        defaultDisplayType === UserPreferenceDisplayTypes.Quotes ? 'QUOTES' : 'NORMAL';

      yield put(timerActions.reset());
      yield put(
        sessionManagerSliceActions.initDynamicSession({
          sessionPlayType,
          sessionActivity: result.dynamicActivity,
        }),
      );
      yield put(
        userActions.setDefaultDisplayType({
          type: UserPreferenceDisplayTypes.Infinite,
        }),
      );

      yield handleTimerSpecificationParsing(timerSpecification);

      yield put(musicActions.receiveQueue(result.servings));

      const currentActivity = result.dynamicActivity;

      // TODO: this function should be examined
      yield put(
        currentSessionSliceActions.updateSession({
          mentalStateId: result.dynamicActivity.mentalState.id,
          activityId: currentActivity.id,
          startedFrom: 'Jump back in',
          playedActivities: [
            {
              id: currentActivity.id,
              name: currentActivity.displayValue,
              type: currentActivity.type,
              duration: 0,
            },
          ],
        }),
      );

      yield put(analyticsActions.sessionLogEvent('session_start'));
    } catch (error) {
      Logger.error(error);
    } finally {
      yield put(sessionManagerSliceActions.update({ sessionStatus: 'idle' }));
    }
  }
}

function* changeSessionDynamicActivitySaga(
  action: ActionCreatorWithPayload<ChangeSessionDynamicActivity>,
) {
  try {
    if (sessionManagerActions.changeSessionDynamicActivity.match(action)) {
      const userInfo: RootReducerType['user']['info'] = yield select(
        (state: RootReducerType) => state.user.info,
      );
      const membership: RootReducerType['user']['membership'] = yield select(
        (state: RootReducerType) => state.user.membership,
      );
      const teams: RootReducerType['teams'] = yield select((state: RootReducerType) => state.teams);
      const verification: RootReducerType['user']['verification'] = yield select(
        (state: RootReducerType) => state.user.verification,
      );

      yield handleCheckVerificationAccess(membership, teams, verification);

      // Set the repeat track to off
      yield put(audioPlayerActions.setPlayStyleToDefault());

      const activeGenres = action.payload.genreNames ? action.payload.genreNames : null;
      const activeNeuralEffectLevels = action.payload.neuralEffectLevels
        ? action.payload.neuralEffectLevels
        : null;

      if (activeGenres || activeNeuralEffectLevels) {
        yield put(
          toggleSessionPreference({
            genreNames: activeGenres,
            neuralEffectLevels: activeNeuralEffectLevels,
          }),
        );
      }

      yield action.payload.navigate(getDynamicActivityPlayerPath(action.payload.activity.id));

      const { sessionManager, currentSession }: RootReducerType = yield select(
        (state: RootReducerType) => state,
      );

      /**
       * We need to calculate time spent in each activity
       * Since we already know the total time spent in a session,
       * we can track parts that were spent in each activity.
       *
       * 1. We track each activity that is played in an array.
       * - when a session starts, it defaults to a specific activity.
       * - we set that activities duration to 0, because it just started and add it to the array.
       * - when activity changes,
       *   - we calculate the duration spent,
       *   - save it,
       *   - add the new activity with duration of 0 to the array
       *
       * 2. How is the duration calculated?
       * - We take the total session play time and add current track progress then
       *   subtract durations from activities we have in the array.
       *   That gives us time spent in 1 activity.
       */
      const totalPlayedInActivitiesSeconds = currentSession.session.playedActivities.reduce(
        (acc: number, playedActivity: PlayedActivity) => {
          return acc + playedActivity.duration;
        },
        0,
      );

      const totalPlayDurationSeconds =
        sessionManager.sessionPlayTime + sessionManager.currentTrackTimeStamp;
      const totalTimePlayedInActivity = totalPlayDurationSeconds - totalPlayedInActivitiesSeconds;
      yield put(
        currentSessionSliceActions.updateLastPlayedActivityDuration(totalTimePlayedInActivity),
      );

      yield put(
        currentSessionSliceActions.addPlayedActivity({
          id: action.payload.activity.id,
          name: action.payload.activity.displayValue,
          type: action.payload.activity.type,
          duration: 0,
        }),
      );

      yield put(
        userSliceActions.setPlayedDynamicActivity({
          [action.payload.activity.mentalState.id]: action.payload.activity.id,
        }),
      );
      yield put(analyticsActions.playerLogEvent('player_activity_switch'));
    }
  } catch (error) {
    // TODO: What to do if tracks fails? Need an alert or something
    Logger.error(error);
  }
}

function* dynamicReplaceTracksWithUpdatedPreferencesSaga(action: Action) {
  try {
    if (sessionManagerActions.dynamicReplaceTracksWithUpdatedPreferences.match(action)) {
      yield put(sessionManagerSliceActions.setTrackStatus('loading'));

      const userInfo: RootReducerType['user']['info'] = yield select(
        (state: RootReducerType) => state.user.info,
      );
      const sessionManager: RootReducerType['sessionManager'] = yield select(
        (state: RootReducerType) => state.sessionManager,
      );

      // Set the repeat track to off
      yield put(audioPlayerActions.setPlayStyleToDefault());

      const session: Session = yield select(getDynamicSession);
      const user: UserState = yield select(getUser);

      const activeGenres = action.payload.genreNames ? action.payload.genreNames : null;
      const activeNeuralEffectLevels = action.payload.neuralEffectLevels
        ? action.payload.neuralEffectLevels
        : null;
      const body: any = {
        dynamicActivityId: sessionManager.sessionDynamicActivity?.id,
        genreNames: session
          ? user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus].genreNames
          : [],
        neuralEffectLevels: session
          ? user.mentalStatePreferences[session.mentalState.id as MentalStates.Focus]
              .neuralEffectLevels
          : [],
      };
      if (activeGenres || activeNeuralEffectLevels) {
        yield put(
          toggleSessionPreference({
            genreNames: activeGenres,
            neuralEffectLevels: activeNeuralEffectLevels,
          }),
        );
        body.genreNames = activeGenres || body.genreNames;
        body.neuralEffectLevels = activeNeuralEffectLevels || body.neuralEffectLevels;
      }

      const { result } = (yield requestSaga(
        RequestMethods.POST,
        `/users/${userInfo?.id}/sessions?platform=web`,
        body,
        3,
      )) as {
        result: { servings: Serving[] };
      };

      if (result) {
        yield put(sessionManagerSliceActions.replaceTracks({ isDeepLinkReferrer: false }));
        yield put(musicActions.receiveQueue(result.servings));
      }

      yield put(sessionManagerSliceActions.incrementTrackOffset());
    }
  } catch (error) {
    yield put(sessionManagerSliceActions.setTrackStatus('idle'));
    Logger.error(error);
  }
}

function* DYNAMIC_fetchMoreTracksSaga(action: Action) {
  try {
    if (sessionManagerActions.DYNAMIC_fetchMoreTracks.match(action)) {
      yield put(sessionManagerSliceActions.setTrackStatus('loading'));

      const { user, sessionManager }: RootReducerType = yield select(
        (state: RootReducerType) => state,
      );
      const dynamicActivity = sessionManager.sessionDynamicActivity;
      const currentUser: UserState = yield select(getUser);

      const body: any = {
        dynamicActivityId: dynamicActivity?.id,
      };

      const activeGenres = dynamicActivity
        ? currentUser.mentalStatePreferences[dynamicActivity.mentalState.id as MentalStates]
            .genreNames
        : null;
      const activeNeuralEffectLevels = dynamicActivity
        ? currentUser.mentalStatePreferences[dynamicActivity.mentalState.id as MentalStates]
            .neuralEffectLevels
        : null;

      if ((activeGenres || []).length) {
        body.genreNames = activeGenres;
      }
      if ((activeNeuralEffectLevels || []).length) {
        body.neuralEffectLevels = activeNeuralEffectLevels;
      }
      const { result } = (yield requestSaga(
        RequestMethods.POST,
        `/users/${user.info?.id}/sessions?platform=web`,
        body,
        3,
      )) as {
        result: { servings: Serving[] };
      };

      if (result) {
        yield put(sessionManagerSliceActions.appendTracks());
        yield put(musicActions.appendQueue(result.servings));
      }

      yield put(sessionManagerSliceActions.incrementTrackOffset());
    }
  } catch (error) {
    yield put(sessionManagerSliceActions.setTrackStatus('idle'));
    Logger.error(error);
  }
}

function* savePastSessionToHistory() {
  const currentSession: RootReducerType['currentSession'] = yield select(
    (state: RootReducerType) => state.currentSession,
  );
  const sessionManager: RootReducerType['sessionManager'] = yield select(
    (state: RootReducerType) => state.sessionManager,
  );
  const currentTrack: RootReducerType['music']['currentTrack'] = yield select(
    (state: RootReducerType) => state.music.currentTrack,
  );

  if (currentSession.session.startedAt && currentTrack) {
    const playDurationSeconds =
      sessionManager.sessionPlayTime + sessionManager.currentTrackTimeStamp;
    yield put(currentSessionSliceActions.updateLastPlayedActivityDuration(playDurationSeconds));
    yield put(
      currentSessionSliceActions.updateSession({
        playDurationSeconds: currentSession.session.playDurationSeconds + playDurationSeconds,
      }),
    );

    yield put(currentSessionSliceActions.clearSession());
  }
}

/** Player Management */

function* startSessionSaga(action: Action) {
  if (sessionManagerActions.startSession.match(action)) {
    const sessionStartTime = Date.now();
    yield put(sessionManagerSliceActions.startSession(sessionStartTime));

    yield put(
      currentSessionSliceActions.updateSession({
        startedAt: sessionStartTime,
      }),
    );
  }
}

function* resumeSaga(action: Action) {
  if (sessionManagerActions.resumeTrack.match(action)) {
    const resumeTimestamp = Date.now();
    yield put(analyticsActions.playerResume(action.payload));
    yield put(sessionManagerSliceActions.resume(resumeTimestamp));
  }
}

function* pauseSaga(action: Action) {
  if (sessionManagerActions.pauseTrack.match(action)) {
    const pauseTimestamp = Date.now();
    yield put(sessionManagerSliceActions.pause(pauseTimestamp));
    yield put(analyticsActions.playerLogEvent('player_pause'));
  }
}

function* previousSaga(action: Action) {
  if (sessionManagerActions.previousTrack.match(action)) {
    const membership: RootReducerType['user']['membership'] = yield select(
      (state: RootReducerType) => state.user.membership,
    );
    const teams: RootReducerType['teams'] = yield select((state: RootReducerType) => state.teams);
    const verification: RootReducerType['user']['verification'] = yield select(
      (state: RootReducerType) => state.user.verification,
    );

    yield handleCheckVerificationAccess(membership, teams, verification);

    yield put(sessionManagerSliceActions.nextTrack());
    yield put(musicActions.playPreviousTrack());
  }
}

function* skipSaga(action: Action) {
  if (sessionManagerActions.skipTrack.match(action)) {
    const { shouldTrackSkip = true } = action.payload;

    const membership: RootReducerType['user']['membership'] = yield select(
      (state: RootReducerType) => state.user.membership,
    );
    const teams: RootReducerType['teams'] = yield select((state: RootReducerType) => state.teams);
    const isRepeatEnabled: boolean = yield select(isAudioPlayerRepeatEnabled);
    const shouldRepeat = isRepeatEnabled && action.payload.shouldHonorRepeat;
    const verification: RootReducerType['user']['verification'] = yield select(
      (state: RootReducerType) => state.user.verification,
    );

    yield handleCheckVerificationAccess(membership, teams, verification);

    if (shouldTrackSkip) {
      yield put(analyticsActions.playerLogEvent('player_skip'));
    }

    yield put(sessionManagerSliceActions.nextTrack());

    if (shouldRepeat) return;
    yield put(musicActions.playNextTrack());
  }
}

function* trackEndedSaga() {
  try {
    const {
      music: { currentTrack },
    }: RootReducerType = yield select((state: RootReducerType) => state);
    Analytics.logEventWithProperties('session_track_complete', {
      id: getTrackId(currentTrack),
      name: getTrackName(currentTrack),
    } as any);
  } catch (error) {
    Logger.error(new Error('trackEndedSaga: unable to track track completion'), { reason: error });
  }
}

/** Timer Management */

function* setTimerSaga(action: Action) {
  if (sessionManagerActions.setSessionTimer.match(action)) {
    const { sessionPlayType, timerLength, timerLengthDisplayValue } = action.payload;

    yield put(sessionManagerSliceActions.setSessionType(sessionPlayType));
    yield put(sessionManagerSliceActions.setTimerLength(timerLength || 0));
    yield put(sessionManagerSliceActions.setTimerLengthDisplayValue(timerLengthDisplayValue!));
    yield put(analyticsActions.playerLogEvent('player_timer_changed'));
  }
}

function* extendTimerSaga(action: Action) {
  if (sessionManagerActions.extendSesionTimer.match(action)) {
    yield put(sessionManagerSliceActions.extendTimerLength(action.payload));
  }
}

function* setSessionTypeSaga(action: Action) {
  if (sessionManagerActions.setSessionType.match(action)) {
    yield put(sessionManagerSliceActions.setSessionType(action.payload));
  }
}

function* setTimerLengthDisplayValueSaga(action: Action) {
  if (sessionManagerActions.setSessionTimerLengthDisplayValue.match(action)) {
    yield put(sessionManagerSliceActions.setTimerLengthDisplayValue(action.payload));
  }
}

function* sessionTimerChangedSaga(action: Action) {
  if (sessionManagerActions.sessionTimerChanged.match(action)) {
    yield put(saveRecentSession());
  }
}

function* endSessionSaga() {
  const { sessionManager, currentSession }: RootReducerType = yield select(
    (state: RootReducerType) => state,
  );

  const totalPlayedInActivitiesSeconds = currentSession.session.playedActivities.reduce(
    (acc: number, playedActivity: PlayedActivity) => {
      return acc + playedActivity.duration;
    },
    0,
  );

  const playDurationSeconds = sessionManager.sessionPlayTime + sessionManager.currentTrackTimeStamp;
  const totalTimePlayedInActivity = playDurationSeconds - totalPlayedInActivitiesSeconds;

  yield put(userSliceActions.updateUserHistory(totalTimePlayedInActivity));

  yield put(
    currentSessionSliceActions.updateSession({
      playDurationSeconds,
    }),
  );

  yield put(currentSessionSliceActions.updateLastPlayedActivityDuration(totalTimePlayedInActivity));

  yield put(sessionManagerSliceActions.endSession());

  yield put(analyticsActions.sessionLogEvent('session_end'));
}

function* setCurrentTrackTimeStampSaga(action: Action) {
  if (sessionManagerActions.setCurrentTrackTimeStamp.match(action)) {
    const {
      currentTrackTimeStamp,
      sessionPassed5Min,
      sessionPlayTime,
      sessionPlayType,
      timerLength,
      timerPlayTime,
    }: SessionManagerStateType = yield select((state: RootReducerType) => state.sessionManager);
    try {
      // TODO - clean this up and test cover.
      const totalMinutesPlayedInPreviousSessions = (yield select(
        (state: RootReducerType) => state.user.usage.totalMinutesPlayed,
      )) as RootReducerType['user']['usage']['totalMinutesPlayed'];
      const totalMinutesPlayedInThisSessionBeforeThisSong =
        sessionPlayTime / 60 + totalMinutesPlayedInPreviousSessions;

      const previousTotalMinutesPlayed =
        totalMinutesPlayedInThisSessionBeforeThisSong + currentTrackTimeStamp / 60;
      const nextTotalMinutesPlayed =
        totalMinutesPlayedInThisSessionBeforeThisSong + action.payload / 60;

      LISTENING_MILESTONES_TO_TRACK.forEach(milestone => {
        if (previousTotalMinutesPlayed < milestone && nextTotalMinutesPlayed >= milestone) {
          trackListeningMilestone({ milestone });
        }
      });
    } catch (e) {
      Logger.error(new Error('unable to track usage events during setCurrentTrackTimeStamp()'), {
        reason: e,
      });
    }

    if (!sessionPassed5Min) {
      const playtime = action.payload + sessionPlayTime;

      if (playtime >= 300) {
        yield put(sessionManagerSliceActions.setPassedFiveMins(true));
        yield put(analyticsActions.sessionLogEvent('session_first_five_minutes'));
      }
    }

    if (sessionPlayType === 'TIMER' && timerLength) {
      if (8 + action.payload + (timerPlayTime || 0) >= timerLength) {
        window.dispatchEvent(new Event('fade-out-slow'));
      }

      if (1 + action.payload + (timerPlayTime || 0) >= timerLength) {
        yield put(sessionManagerSliceActions.setTimerFinished(true));
      }
    }

    yield put(sessionManagerSliceActions.setTrackTimestamp(action.payload));
  }
}

function* trackCardLoadSaga(action: Action) {
  if (sessionManagerActions.trackCardLoad.match(action)) {
    try {
      const { id }: UserInfoType = yield select((state: RootReducerType) => state.user.info);
      const { sessionManager }: RootReducerType = yield select((state: RootReducerType) => state);
      const membership: RootReducerType['user']['membership'] = yield select(
        (state: RootReducerType) => state.user.membership,
      );
      const teams: RootReducerType['teams'] = yield select((state: RootReducerType) => state.teams);
      const verification: RootReducerType['user']['verification'] = yield select(
        (state: RootReducerType) => state.user.verification,
      );

      yield handleCheckVerificationAccess(membership, teams, verification);

      // Set the repeat track to off
      yield put(audioPlayerActions.setPlayStyleToDefault());

      // we will let the currently playing song fade out first
      window.dispatchEvent(new Event('playlist-update'));

      yield put(uiSliceActions.setModalType(null));
      if (action.payload.isUsingDynamicActivities) {
        const { result: session } = (yield requestSaga(
          RequestMethods.POST,
          `/users/${id}/sessions`,
          {
            dynamicActivityId: `${DA_PREFIXES.SIMILAR}${action.payload.trackId}`,
          },
          3,
        )) as { result?: DynamicSession };
        if (!session?.servings.length) return;

        // let the song fade out completely before loading the new playlist.
        if (sessionManager.sessionPlayStatus === 'PLAYING') {
          yield delay(1000);
        }

        yield put(musicActions.prependQueue([...session?.servings]));
        yield put(
          sessionManagerSliceActions.replaceTracks({
            isDeepLinkReferrer: Boolean(action.payload.loadWithoutPlaying),
          }),
        );
        yield put(
          sessionManagerSliceActions.update({ sessionDynamicActivity: session.dynamicActivity }),
        );
      } else {
        const path = action.payload.trackVariationId
          ? `/sessions/tracks/variations/${action.payload.trackVariationId}`
          : `/sessions/tracks/${action.payload.trackId}`;
        const { result: session } = (yield requestSaga(
          RequestMethods.POST,
          `/users/${id}${path}?platform=web`,
          undefined,
          3,
        )) as { result?: V3Session };

        const newServing = session?.servings[0];
        if (!newServing) return;

        // let the song fade out completely before loading the new playlist.
        if (sessionManager.sessionPlayStatus === 'PLAYING') {
          yield delay(1000);
        }

        // TODO consolidate
        yield put(musicActions.prependQueue([newServing]));
        yield put(
          sessionManagerSliceActions.replaceTracks({
            isDeepLinkReferrer: Boolean(action.payload.loadWithoutPlaying),
          }),
        );

        const activityForServing = newServing.track.tags.find(
          tag => tag.type === TagTypes.Activity,
        )?.value;
        const activityId = Object.values(activities.byId).find(
          activity => activity.display === activityForServing,
        )?._id;
        yield put(sessionManagerSliceActions.update({ sessionActivityId: activityId }));
      }
    } catch (error) {
      Logger.error(error);
    }
  }
}

function* timerFinishedSaga(action: Action) {
  if (sessionManagerActions.timerFinished.match(action)) {
    yield put(
      uiActions.successModalOpen({
        isDismissable: false,
        title: 'Your timer has finished!',
        actions: [
          {
            text: 'RESTART TIMER',
            type: 'primary',
            action: sessionManagerActions.timerFinishedRestart(),
            analyticsEvent: 'session_timer_end_restart',
          },
          {
            text: 'CONTINUE WITHOUT TIMER',
            type: 'primary',
            action: sessionManagerActions.timerFinishedKeepGoing(),
            analyticsEvent: 'session_timer_end_keep_going',
          },
        ],
      }),
    );
  }
}

function* timerFinishedRestartSaga(action: Action) {
  if (sessionManagerActions.timerFinishedRestart.match(action)) {
    const { timerLength }: SessionManagerStateType = yield select(
      (state: RootReducerType) => state.sessionManager,
    );

    yield put(sessionManagerActions.extendSesionTimer(timerLength || 0));
    yield put(uiActions.successModalClose());
  }
}

function* timerFinishedKeepGoingSaga(action: Action) {
  if (sessionManagerActions.timerFinishedKeepGoing.match(action)) {
    yield put(sessionManagerSliceActions.extendTimerInfinity());
    yield put(uiActions.successModalClose());
  }
}

// clear saga always last
function* clearSessionManagerSaga(action: Action) {
  if (sessionManagerActions.clearSessionManager.match(action)) {
    yield put(sessionManagerSliceActions.clearState());
  }
}

function* handleTimerSpecificationParsing(timerSpecification: TimerSpecification) {
  if (timerSpecification.type === 'countdown') {
    const duration = timerSpecification.durations[0];
    yield put(
      sessionManagerActions.setSessionTimer({
        sessionPlayType: 'TIMER',
        timerLength: duration.valueInSeconds,
        timerLengthDisplayValue: duration.displayValue,
      }),
    );
  } else if (timerSpecification.type === 'interval') {
    const durations = timerSpecification.durations;
    const focusDuration = durations[0].valueInSeconds / 60;
    const breakDuration = durations[1].valueInSeconds / 60;

    const sessionLengthDisplayValue = durations.map(duration => duration.displayValue).join(', ');

    yield put(sessionManagerActions.setSessionType('NORMAL'));
    yield put(sessionManagerActions.setSessionTimerLengthDisplayValue(sessionLengthDisplayValue));
    yield put(timerActions.turnOnPomodoroMode());
    yield put(
      timerActions.setPomodoroIntervals({
        focusTime: `${focusDuration}`,
        breakTime: `${breakDuration}`,
      }),
    );
    yield put(
      userActions.setDefaultDisplayType({
        type: UserPreferenceDisplayTypes.Pomodoro,
      }),
    );
  }
}

export default function* watchAudioPlayerSaga() {
  yield takeLatest(sessionManagerActions.DYNAMIC_fetchMoreTracks.type, DYNAMIC_fetchMoreTracksSaga);
  yield takeLatest(sessionManagerActions.startSession.type, startSessionSaga);

  yield takeEvery(sessionManagerActions.resumeTrack.type, resumeSaga);
  yield takeEvery(sessionManagerActions.pauseTrack.type, pauseSaga);
  yield takeEvery(sessionManagerActions.previousTrack.type, previousSaga);
  yield takeEvery(sessionManagerActions.skipTrack.type, skipSaga);
  yield takeEvery(sessionManagerActions.endSession.type, endSessionSaga);
  yield takeEvery(sessionManagerActions.trackEnded.type, trackEndedSaga);

  // timers
  yield takeEvery(sessionManagerActions.setSessionTimer.type, setTimerSaga);
  yield takeEvery(sessionManagerActions.extendSesionTimer.type, extendTimerSaga);

  yield takeEvery(sessionManagerActions.setSessionType.type, setSessionTypeSaga);
  yield takeEvery(
    sessionManagerActions.setSessionTimerLengthDisplayValue.type,
    setTimerLengthDisplayValueSaga,
  );
  yield takeEvery(sessionManagerActions.sessionTimerChanged.type, sessionTimerChangedSaga);
  yield takeEvery(
    sessionManagerActions.setCurrentTrackTimeStamp.type,
    setCurrentTrackTimeStampSaga,
  );

  yield takeEvery(sessionManagerActions.trackCardLoad.type, trackCardLoadSaga);

  yield takeEvery(sessionManagerActions.timerFinished.type, timerFinishedSaga);
  yield takeEvery(sessionManagerActions.timerFinishedRestart.type, timerFinishedRestartSaga);
  yield takeEvery(sessionManagerActions.timerFinishedKeepGoing.type, timerFinishedKeepGoingSaga);

  // clear saga always last
  yield takeEvery(sessionManagerActions.clearSessionManager.type, clearSessionManagerSaga);

  yield takeLatest(sessionManagerActions.createDynamicSession.type, createDynamicSessionSaga);
  yield takeLatest(
    sessionManagerActions.createDynamicSessionFromJumpBackIn.type,
    createDynamicSessionFromJumpBackInSaga,
  );
  yield takeLatest(
    sessionManagerActions.changeSessionDynamicActivity.type,
    changeSessionDynamicActivitySaga,
  );
  yield takeLatest(
    sessionManagerActions.dynamicReplaceTracksWithUpdatedPreferences.type,
    dynamicReplaceTracksWithUpdatedPreferencesSaga,
  );
}

function* handleCheckVerificationAccess(
  membership: RootReducerType['user']['membership'],
  teams: RootReducerType['teams'],
  verification: RootReducerType['user']['verification'],
) {
  if (!isAllowedToPlaySession(membership, teams, verification)) {
    if (hasTeamAccess(teams) && !isEmailVerified(verification)) {
      yield promptUserToVerifyEmail();
      return;
    }
    yield promptUserToSubscribe();
    return;
  }
}

function* promptUserToSubscribe() {
  yield put(uiSliceActions.setModalType('paywall'));
}

function* promptUserToVerifyEmail() {
  yield put(uiSliceActions.setModalType('verifyEmail'));
}

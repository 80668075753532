import { MembershipWidget } from '@Memberships';
import { useShouldRenderMembershipWidget } from '../../domains/Memberships/components/MembershipWidget/hooks/useShouldRenderMembershipWidget';
import { ReferAFriendWidget } from '../../domains/Memberships/components/ReferAFriendWidget';
import { useShouldRenderReferAFriend } from '../../domains/Memberships/components/ReferAFriendWidget/hooks/useShouldRenderReferAFriend';
import { useTeamSubscriptionInfo } from '../../hooks/useHasTeamSubscription';

import * as S from './Widget.styles';

export const Widget = () => {
  const { isDataReady } = useTeamSubscriptionInfo();
  const shouldRenderMembershipWidget = useShouldRenderMembershipWidget();
  const shouldRenderReferAFriend = useShouldRenderReferAFriend();

  if (!isDataReady) {
    // not rendering widgets until we fetch all the necessary data (teams subscription)
    return null;
  }

  if (shouldRenderMembershipWidget) {
    return (
      <S.WidgetWrapper>
        <MembershipWidget testid="membershipWidgetSessionHeader" />
      </S.WidgetWrapper>
    );
  }

  if (shouldRenderReferAFriend) {
    return (
      <S.WidgetWrapper>
        <ReferAFriendWidget />
      </S.WidgetWrapper>
    );
  }

  return null;
};

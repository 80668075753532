import { Elements } from '@stripe/react-stripe-js';

import stripe, { ELEMENT_OPTIONS } from '../../../../utils/stripe';
import { getPaywallVariationFeatureFlag } from '../../../Utils/useAmplitudeExperiments';
import { usePaywallGateway } from './hooks/usePaywallGateway';
import { OnboardingTestimonials } from '../../../Onboarding/types';
import { useTrackSubscriptionImpression } from './hooks/useTrackSubscriptionImpression';
import { useEffect } from 'react';
import { Analytics } from '../../../../utils/analytics';
import { SingleScreenSlideUpPaywall } from './variations/SingleScreenSlideUpPaywall';
import { ExpressCheckoutPaywall } from './variations/ExpressCheckoutPaywall';

type Props = {
  paywallBenefits?: string[];
  paywallTestimonials?: OnboardingTestimonials[];
  onPaymentComplete: () => void;
  onClose: () => void;
};

export function PaywallManagerContainer(props: Props) {
  usePaywallGateway();
  useTrackSubscriptionImpression();
  const paywallVariationFlag = getPaywallVariationFeatureFlag();

  useEffect(() => {
    Analytics.setUserProperty(paywallVariationFlag, true);
  }, []);

  if (paywallVariationFlag === 'express_checkout') {
    return (
      <Elements
        options={{ mode: 'setup', currency: 'usd', amount: 0, paymentMethodCreation: 'manual' }}
        stripe={stripe.getInstance()}
      >
        <ExpressCheckoutPaywall
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  return (
    <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
      <SingleScreenSlideUpPaywall
        paywallBenefits={props.paywallBenefits}
        paywallTestimonials={props.paywallTestimonials}
        onClose={props.onClose}
        onPaymentComplete={props.onPaymentComplete}
      />
    </Elements>
  );
}

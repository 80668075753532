import { EffectLevels } from '@Model';
import * as Braze from '@braze/web-sdk';
import Amplitude from 'amplitude-js';

import { getErrorMessage } from '../../utils/getErrorMessage';
import { Logger } from '../../utils/logger';
import { TimerMode } from '../Session/components/TimerSettings/hooks/useTrackTimerModeChange';
import { BRAZE_BLACKLISTED_EVENTS } from './constants';
import {
  CoreAnalyticsEvent,
  CoreAnalyticsEventTypes,
  SignUpMethods,
  CoreAnalyticsEventProperties,
  OnboardingOrigins,
  OnboardingModules,
  PlanTypes,
  SubscriptionImpressionOrigin,
  FilterTypes,
  MentalStates,
  TimerOrigin,
  TrackActionOrigins,
} from './coreAnalytics.types';

export function trackOnboardingCardImpressionEvent(params: {
  title: string;
  body: string;
  step: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingCardImpression,
    payload: {
      [CoreAnalyticsEventProperties.OnboardingTitle]: params.title,
      [CoreAnalyticsEventProperties.OnboardingBody]: params.body,
      [CoreAnalyticsEventProperties.OnboardingStep]: params.step,
    },
  });
}

export function trackOnboardingFaqQuestionImpressionEvent(params: { title: string; step: string }) {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingFaqQuestionImpression,
    payload: {
      [CoreAnalyticsEventProperties.OnboardingFaqTitle]: params.title,
      [CoreAnalyticsEventProperties.OnboardingStep]: params.step,
    },
  });
}

export function trackOnboardingCompleteEvent(params: { neurotypeId: string; variation: string }) {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingComplete,
    payload: {
      [CoreAnalyticsEventProperties.NeurotypeId]: params.neurotypeId,
      [CoreAnalyticsEventProperties.OnboardingVariation]: params.variation,
    },
  });
}

export function trackOnboardingContinueEvent() {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingContinue,
  });
}

export function trackOnboardingFunBarCompleteEvent() {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingFunBarComplete,
  });
}

export function trackOnboardingFunBarImpressionEvent() {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingFunBarImpression,
  });
}

export function trackOnboardingInitiateEvent(params: {
  variation: string;
  origin: OnboardingOrigins;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingInitiate,
    payload: {
      [CoreAnalyticsEventProperties.OnboardingVariation]: params.variation,
      [CoreAnalyticsEventProperties.OnboardingOrigin]: params.origin,
    },
  });
}

export function trackOnboardingMentalStateImpressionEvent() {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingMentalStateImpression,
  });
}

export function trackOnboardingMentalStateSelectEvent(params: { mentalState: string }) {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingMentalStateSelect,
    payload: {
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
    },
  });
}

export function trackOnboardingQuizAnswer(params: { answer: string }) {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingQuizAnswer,
    payload: {
      [CoreAnalyticsEventProperties.OnboardingAnswer]: params.answer,
    },
  });
}

export function trackOnboardingQuizQuestionImpressionEvent(params: {
  title: string;
  body: string;
  step: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingQuizQuestionImpression,
    payload: {
      [CoreAnalyticsEventProperties.OnboardingTitle]: params.title,
      [CoreAnalyticsEventProperties.OnboardingBody]: params.body,
      [CoreAnalyticsEventProperties.OnboardingStep]: params.step,
    },
  });
}

export function trackOnboardingSkipEvent(params: {
  variation: string;
  cardIndex: number;
  cardTitle: string;
  module: OnboardingModules;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.OnboardingSkip,
    payload: {
      [CoreAnalyticsEventProperties.OnboardingCardIndex]: params.cardIndex,
      [CoreAnalyticsEventProperties.OnboardingCardTitle]: params.cardTitle,
      [CoreAnalyticsEventProperties.OnboardingModule]: params.module,
      [CoreAnalyticsEventProperties.OnboardingVariation]: params.variation,
    },
  });
}

export function trackSignInAttempt(params: { method: SignUpMethods }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SignInAttempt,
    payload: {
      [CoreAnalyticsEventProperties.SignInMethod]: params.method,
    },
  });
}

export function trackSignInError(params: { method: SignUpMethods; error: unknown }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SignInError,
    payload: {
      [CoreAnalyticsEventProperties.SignInMethod]: params.method,
      [CoreAnalyticsEventProperties.ErrorMessage]: getErrorMessage(params.error),
    },
  });
}

export function trackSignInSuccess(params: { method: SignUpMethods }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SignInSuccess,
    payload: {
      [CoreAnalyticsEventProperties.SignInMethod]: params.method,
    },
  });
}

export function trackSignUpAttempt(params: { method: SignUpMethods }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SignUpAttempt,
    payload: {
      [CoreAnalyticsEventProperties.SignUpMethod]: params.method,
      [CoreAnalyticsEventProperties.SignUpLocation]: 'app',
    },
  });
}

export function trackSignUpError(params: { method: SignUpMethods; error: unknown }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SignUpError,
    payload: {
      [CoreAnalyticsEventProperties.SignUpMethod]: params.method,
      [CoreAnalyticsEventProperties.ErrorMessage]: getErrorMessage(params.error),
      [CoreAnalyticsEventProperties.SignUpLocation]: 'app',
    },
  });
}

export function trackSignUpSuccess(params: { method: SignUpMethods }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SignUpSuccess,
    payload: {
      [CoreAnalyticsEventProperties.SignUpMethod]: params.method,
      [CoreAnalyticsEventProperties.SignUpLocation]: 'app',
    },
  });
}

export function trackReferralLinkCopied() {
  logEvent({
    type: CoreAnalyticsEventTypes.ReferralLinkCopied,
  });
}

export function trackSubscriptionStartAttempt(params: { planType: PlanTypes; couponId: string }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SubscriptionStartAttempt,
    payload: {
      [CoreAnalyticsEventProperties.PlanType]: params.planType,
      [CoreAnalyticsEventProperties.CouponId]: params.couponId,
    },
  });
}

export function trackStripeCheckoutSubscriptionStartAttempt(params: {
  planType: PlanTypes;
  couponId: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.StripeCheckoutSubscriptionStartAttempt,
    payload: {
      [CoreAnalyticsEventProperties.PlanType]: params.planType,
      [CoreAnalyticsEventProperties.CouponId]: params.couponId,
    },
  });
}

export function trackSubscriptionStartSuccess(params: {
  planType?: PlanTypes;
  couponId?: string;
  isStripeCheckout: boolean;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.SubscriptionStartSuccess,
    payload: {
      [CoreAnalyticsEventProperties.PlanType]: params.planType,
      [CoreAnalyticsEventProperties.CouponId]: params.couponId,
      is_stripe_checkout: params.isStripeCheckout,
    },
  });
}

export function trackStripeCheckoutSubscriptionStartSuccess(params: {
  planType: PlanTypes;
  couponId: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.StripeCheckoutSubscriptionStartSuccess,
    payload: {
      [CoreAnalyticsEventProperties.PlanType]: params.planType,
      [CoreAnalyticsEventProperties.CouponId]: params.couponId,
    },
  });
}

export function trackStripeCheckoutSubscriptionStartError(params: { errorMessage: string }) {
  logEvent({
    type: CoreAnalyticsEventTypes.StripeCheckoutSubscriptionStartError,
    payload: {
      [CoreAnalyticsEventProperties.ErrorMessage]: params.errorMessage,
    },
  });
}

export function trackSubscriptionStartError(params: { errorMessage: string }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SubscriptionStartError,
    payload: {
      [CoreAnalyticsEventProperties.ErrorMessage]: params.errorMessage,
    },
  });
}

export function trackSubscriptionPlanChangeSuccess(params: {
  oldPlanType: PlanTypes;
  newPlanType: PlanTypes;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.SubscriptionPlanChangeSuccess,
    payload: {
      [CoreAnalyticsEventProperties.OldPlanType]: params.oldPlanType,
      [CoreAnalyticsEventProperties.NewPlanType]: params.newPlanType,
    },
  });
}

export function trackSubscriptionPlanChangeError(params: { errorMessage: string }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SubscriptionPlanChangeError,
    payload: {
      [CoreAnalyticsEventProperties.ErrorMessage]: params.errorMessage,
    },
  });
}

export function trackSubscriptionPlanLeaveEvent() {
  logEvent({
    type: CoreAnalyticsEventTypes.SubscriptionPlanLeaveEvent,
  });
}

export function trackSubscriptionPlanSelectEvent(params: { planType: PlanTypes }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SubscriptionPlanSelectEvent,
    payload: {
      [CoreAnalyticsEventProperties.PlanType]: params.planType,
    },
  });
}

export function trackSubscriptionImpression(params: {
  couponId?: string;
  origin: SubscriptionImpressionOrigin;
  expirationModalTest?: string;
  isStripeCheckout: boolean;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.SubscriptionImpression,
    payload: {
      [CoreAnalyticsEventProperties.CouponId]: params.couponId,
      [CoreAnalyticsEventProperties.Origin]: params.origin,
      expiration_modal_test: params.expirationModalTest,
      is_stripe_checkout: params.isStripeCheckout,
    },
  });
}

export function trackFilterPreferencesOpened(params: {
  filterType: FilterTypes;
  mentalState: MentalStates;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.FilterPreferencesOpened,
    payload: {
      [CoreAnalyticsEventProperties.FilterType]: params.filterType,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
    },
  });
}

export function trackFilterPreferencesChanged(params: {
  filterType: FilterTypes;
  mentalState: MentalStates;
  newFilterActivity?: string;
  oldFilterActivity?: string;
  newFilterGenre?: string;
  oldFilterGenre?: string;
  newFilterNel?: string;
  oldFilterNel?: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.FilterPreferencesChanged,
    payload: {
      [CoreAnalyticsEventProperties.FilterType]: params.filterType,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.NewFilterActivity]: params.newFilterActivity,
      [CoreAnalyticsEventProperties.OldFilterActivity]: params.oldFilterActivity,
      [CoreAnalyticsEventProperties.NewFilterGenre]: params.newFilterGenre,
      [CoreAnalyticsEventProperties.OldFilterGenre]: params.oldFilterGenre,
      [CoreAnalyticsEventProperties.NewFilterNel]: params.newFilterNel,
      [CoreAnalyticsEventProperties.OldFilterNel]: params.oldFilterNel,
    },
  });
}

export function trackTimerSettingsOpened(params: {
  activity: string;
  mentalState: MentalStates;
  trackGenre: string;
  filterGenre: string;
  filterNel: string;
  trackNel: string;
  origin: TimerOrigin;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.TimerSettingsOpened,
    payload: {
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.FilterGenre]: params.filterGenre,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      [CoreAnalyticsEventProperties.FilterNel]: params.filterNel,
      [CoreAnalyticsEventProperties.Origin]: params.origin,
    },
  });
}

export function trackTimerStartEvent(params: {
  timerMode: TimerMode;
  activity: string;
  mentalState: MentalStates;
  trackGenre: string;
  filterGenre: string;
  filterNel: string;
  trackNel: string;
  timerLength: string;
  origin: TimerOrigin;
  timerIntervalSoundType?: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.TimerStart,
    payload: {
      [CoreAnalyticsEventProperties.TimerMode]: params.timerMode,
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.FilterGenre]: params.filterGenre,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      [CoreAnalyticsEventProperties.FilterNel]: params.filterNel,
      [CoreAnalyticsEventProperties.TimerLength]: params.timerLength,
      [CoreAnalyticsEventProperties.Origin]: params.origin,
      ...(params.timerIntervalSoundType
        ? { [CoreAnalyticsEventProperties.TimerIntervalSound]: params.timerIntervalSoundType }
        : {}),
    },
  });
}

export function trackSelectTrackEvent(params: { trackName: string; origin: TrackActionOrigins }) {
  logEvent({
    type: CoreAnalyticsEventTypes.SelectTrack,
    payload: {
      [CoreAnalyticsEventProperties.TrackName]: params.trackName,
      [CoreAnalyticsEventProperties.Origin]: params.origin,
    },
  });
}

export function trackShareTrackEvent(params: {
  timerMode: TimerMode;
  activity: string;
  mentalState: MentalStates;
  trackGenre: string;
  filterGenre: string;
  filterNel: string;
  trackNel: string;
  complexity: EffectLevels;
  trackName: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.ShareTrack,
    payload: {
      [CoreAnalyticsEventProperties.TimerMode]: params.timerMode,
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.FilterGenre]: params.filterGenre,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      [CoreAnalyticsEventProperties.FilterNel]: params.filterNel,
      [CoreAnalyticsEventProperties.Complexity]: params.complexity,
      [CoreAnalyticsEventProperties.TrackName]: params.trackName,
    },
  });
}

export function trackTrackStartEvent(params: {
  timerMode: TimerMode;
  activity: string;
  mentalState: MentalStates | string;
  trackGenre: string;
  filterGenre: string;
  filterNel: string;
  trackNel: string;
  complexity: EffectLevels;
  trackName: string;
  dynamicActivityId: string;
  isNewlyCreated: boolean;
  isFavorited: boolean;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.TrackStart,
    payload: {
      [CoreAnalyticsEventProperties.TimerMode]: params.timerMode,
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.FilterGenre]: params.filterGenre,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      [CoreAnalyticsEventProperties.FilterNel]: params.filterNel,
      [CoreAnalyticsEventProperties.Complexity]: params.complexity,
      [CoreAnalyticsEventProperties.TrackName]: params.trackName,
      [CoreAnalyticsEventProperties.DynamicActivityId]: params.dynamicActivityId,
      [CoreAnalyticsEventProperties.IsNewlyCreated]: params.isNewlyCreated,
      [CoreAnalyticsEventProperties.IsFavorited]: params.isFavorited,
    },
  });
}

export function trackPreviousTrackEvent(params: {
  timerMode: TimerMode;
  activity: string;
  mentalState: MentalStates;
  trackGenre: string;
  filterGenre: string;
  filterNel: string;
  trackNel: string;
  complexity: EffectLevels;
  trackName: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.PreviousTrack,
    payload: {
      [CoreAnalyticsEventProperties.TimerMode]: params.timerMode,
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.FilterGenre]: params.filterGenre,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      [CoreAnalyticsEventProperties.FilterNel]: params.filterNel,
      [CoreAnalyticsEventProperties.Complexity]: params.complexity,
      [CoreAnalyticsEventProperties.TrackName]: params.trackName,
    },
  });
}

export function trackSkipTrackEvent(params: {
  timerMode: TimerMode;
  activity: string;
  mentalState: MentalStates;
  trackGenre: string;
  filterGenre: string;
  filterNel: string;
  trackNel: string;
  complexity: EffectLevels;
  trackName: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.SkipTrack,
    payload: {
      [CoreAnalyticsEventProperties.TimerMode]: params.timerMode,
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.FilterGenre]: params.filterGenre,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      [CoreAnalyticsEventProperties.FilterNel]: params.filterNel,
      [CoreAnalyticsEventProperties.Complexity]: params.complexity,
      [CoreAnalyticsEventProperties.TrackName]: params.trackName,
    },
  });
}

export function trackFavoriteTrackEvent(params: {
  activity: string;
  complexity: EffectLevels;
  mentalState: MentalStates;
  origin: TrackActionOrigins;
  trackGenre: string;
  trackName: string;
  trackNel: string;
  trackSeekPosition?: number;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.FavoriteTrack,
    payload: {
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.Complexity]: params.complexity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.Origin]: params.origin,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.TrackName]: params.trackName,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      ...(params.trackSeekPosition
        ? { [CoreAnalyticsEventProperties.TrackSeekPosition]: params.trackSeekPosition }
        : {}),
    },
  });
}

export function trackDislikeTrackEvent(params: {
  activity: string;
  mentalState: MentalStates;
  trackGenre: string;
  trackNel: string;
  complexity: EffectLevels;
  trackName: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.DislikeTrack,
    payload: {
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      [CoreAnalyticsEventProperties.Complexity]: params.complexity,
      [CoreAnalyticsEventProperties.TrackName]: params.trackName,
    },
  });
}

export function trackLoopTrackEvent(params: {
  activity: string;
  mentalState: MentalStates;
  trackGenre: string;
  filterGenre: string;
  filterNel: string;
  trackNel: string;
  complexity: EffectLevels;
  trackName: string;
}) {
  logEvent({
    type: CoreAnalyticsEventTypes.LoopTrack,
    payload: {
      [CoreAnalyticsEventProperties.Activity]: params.activity,
      [CoreAnalyticsEventProperties.MentalState]: params.mentalState,
      [CoreAnalyticsEventProperties.TrackGenre]: params.trackGenre,
      [CoreAnalyticsEventProperties.FilterGenre]: params.filterGenre,
      [CoreAnalyticsEventProperties.TrackNel]: params.trackNel,
      [CoreAnalyticsEventProperties.FilterNel]: params.filterNel,
      [CoreAnalyticsEventProperties.Complexity]: params.complexity,
      [CoreAnalyticsEventProperties.TrackName]: params.trackName,
    },
  });
}

export function trackDeepLinkOpenChromeIos() {
  logEvent({
    type: CoreAnalyticsEventTypes.DeepLinkOpenChromeIos,
  });
}

export function trackPlayerArrowNavigateBack() {
  logEvent({
    type: CoreAnalyticsEventTypes.NavigateHome,
  });
}

function logEvent(event: CoreAnalyticsEvent) {
  try {
    Amplitude.getInstance().logEvent(event.type, event.payload);
  } catch (e) {
    Logger.error(new Error('CoreAnalytics#logEvent(): failed to track amplitude event.'), {
      reason: e,
    });
  }

  try {
    if (!BRAZE_BLACKLISTED_EVENTS.includes(event.type)) {
      Braze.logCustomEvent(event.type, event.payload);
    }
  } catch (e) {
    Logger.error(new Error('CoreAnalytics#logEvent(): failed to track braze event.'), {
      reason: e,
    });
  }
}

import { ButtonVariantStates, ButtonVariants, Panel, Button } from '@Cortex';
import { Assets } from '../../../utils/assets';

import * as S from './Refer.styles';
import CopyIcon from './assets/copy.svg';
import { forwardRef } from 'react';

interface Props {
  buttonState: { text: string; state: ButtonVariantStates };
  onButtonClick: () => void;
}

export const ReferDisplay = forwardRef<HTMLDivElement, Props>(
  ({ buttonState, onButtonClick }, ref) => {
    return (
      <S.Container ref={ref}>
        <S.Content>
          <S.Image src={Assets.images.refer.url} />
          <S.BottomPart>
            <S.TitleText>Share the power of Brain.fm</S.TitleText>
            <S.SubtitleText>
              Share this link with anyone, and they'll enjoy a
              <S.Highlighted> 30-day free trial of Brain.fm</S.Highlighted>
            </S.SubtitleText>
            <S.ButtonWrapper>
              <Button
                data-testid="referLinkCopyButton"
                keepTextCase={true}
                style={{ fontSize: '0.75rem', width: '100%' }}
                variant={ButtonVariants.Secondary}
                variantState={buttonState.state}
                onClick={onButtonClick}
              >
                <S.ButtonContent>
                  {buttonState.state === ButtonVariantStates.Idle ? (
                    <S.ButtonImage src={CopyIcon} />
                  ) : null}
                  {buttonState.text}
                </S.ButtonContent>
              </Button>
            </S.ButtonWrapper>
          </S.BottomPart>
        </S.Content>
      </S.Container>
    );
  },
);
